import { defineStore } from "pinia";
import { ref } from "vue";
import { ResponseType } from "~/types/general";
import {
  Shift,
  ShiftBreakType,
  ShiftDepartment,
  ShiftType,
  ShiftsTimesheet,
} from "~/types/shifts";

export const useShiftsStore = defineStore("shifts", () => {
  // Shifts state
  const shifts = ref<Shift[]>([]);

  // Shifts actions
  async function getShifts(params?): Promise<ResponseType<Shift[]>> {
    try {
      const response = await useVaniloApi("/shifts", { params });
      return response as ResponseType<Shift[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function startShift(body: {
    staff_id: number;
    pin: string | number;
    store_id?: number | string;
  }): Promise<ResponseType<Shift>> {
    try {
      const response = await useVaniloApi("/shifts/start", {
        method: "POST",
        body,
      });
      return response as ResponseType<Shift>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function endShift(staff_id: number): Promise<ResponseType<Shift>> {
    try {
      const response = await useVaniloApi(`/shifts/end`, {
        method: "POST",
        body: { staff_id },
      });
      return response as ResponseType<Shift>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function startBreak(body: {
    staff_id: number;
    break_type_id: number;
  }): Promise<ResponseType<Shift>> {
    try {
      const response = await useVaniloApi(`/shifts/break-start`, {
        method: "POST",
        body,
      });
      return response as ResponseType<Shift>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function endBreak(body: {
    staff_id: number;
    pin: string | number;
    cancelled?: boolean;
  }): Promise<ResponseType<Shift>> {
    try {
      const response = await useVaniloApi("/shifts/break-end", {
        method: "POST",
        body,
      });
      return response as ResponseType<Shift>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Departments state
  const departments = ref<ShiftDepartment[]>([]);
  const viewingDepartment = ref<ShiftDepartment>({} as ShiftDepartment);

  // Departments actions
  async function getDepartments(): Promise<ResponseType<ShiftDepartment[]>> {
    try {
      const response = await useVaniloApi("/departments");
      return response as ResponseType<ShiftDepartment[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createDepartment(body: {
    name: string;
    description?: string;
  }): Promise<ResponseType<ShiftDepartment>> {
    try {
      const response = await useVaniloApi("/departments", {
        method: "POST",
        body,
      });
      return response as ResponseType<ShiftDepartment>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateDepartment(
    id: number,
    body: { name: string; description?: string }
  ): Promise<ResponseType<ShiftDepartment>> {
    try {
      const response = await useVaniloApi(`/departments/${id}`, {
        method: "POST",
        body,
      });
      return response as ResponseType<ShiftDepartment>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteDepartment(
    id: number
  ): Promise<ResponseType<ShiftDepartment>> {
    try {
      const response = await useVaniloApi(`/departments/${id}`, {
        method: "DELETE",
      });
      return response as ResponseType<ShiftDepartment>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Shift Types state
  const shiftTypes = ref<ShiftType[]>([]);
  const viewingShiftType = ref<ShiftType>({} as ShiftType);

  // Shift Types actions
  async function getShiftTypes(): Promise<ResponseType<ShiftType[]>> {
    try {
      const response = await useVaniloApi("/shift-types");
      return response as ResponseType<ShiftType[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createShiftType(body: {
    name: string;
    description?: string;
    start_time: string;
    end_time: string;
  }): Promise<ResponseType<ShiftType>> {
    try {
      const response = await useVaniloApi("/shift-types", {
        method: "POST",
        body,
      });
      return response as ResponseType<ShiftType>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateShiftType(
    id: number,
    body: {
      name: string;
      description?: string;
      start_time: string;
      end_time: string;
    }
  ): Promise<ResponseType<ShiftType>> {
    try {
      const response = await useVaniloApi(`/shift-types/${id}`, {
        method: "POST",
        body,
      });
      return response as ResponseType<ShiftType>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteShiftType(id: number): Promise<ResponseType<ShiftType>> {
    try {
      const response = await useVaniloApi(`/shift-types/${id}`, {
        method: "DELETE",
      });
      return response as ResponseType<ShiftType>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Break Types state
  const breakTypes = ref<ShiftBreakType[]>([]);
  const viewingBreakType = ref<ShiftBreakType>({} as ShiftBreakType);

  // Break Types actions
  async function getBreakTypes(): Promise<ResponseType<ShiftBreakType[]>> {
    try {
      const response = await useVaniloApi("/break-types");
      return response as ResponseType<ShiftBreakType[]>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function createBreakType(body: {
    name: string;
    description?: string;
    hours: number | string;
    minutes: number | string;
  }): Promise<ResponseType<ShiftBreakType>> {
    try {
      const response = await useVaniloApi("/break-types", {
        method: "POST",
        body,
      });
      return response as ResponseType<ShiftBreakType>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function updateBreakType(
    id: number,
    body: {
      name: string;
      description?: string;
      hours: number | string;
      minutes: number | string;
    }
  ): Promise<ResponseType<ShiftBreakType>> {
    try {
      const response = await useVaniloApi(`/break-types/${id}`, {
        method: "POST",
        body,
      });
      return response as ResponseType<ShiftBreakType>;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function deleteBreakType(
    id: number
  ): Promise<ResponseType<ShiftBreakType>> {
    try {
      const response = await useVaniloApi(`/break-types/${id}`, {
        method: "DELETE",
      });
      return response as ResponseType<ShiftBreakType>;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Export Timesheet action
  async function exportTimesheet(
    body: ShiftsTimesheet
  ): Promise<ResponseType<any>> {
    try {
      const response = await useVaniloApi("/shifts/report", {
        method: "POST",
        body,
      });
      return response as ResponseType<any>;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    // Shifts
    shifts,
    getShifts,
    startShift,
    endShift,
    startBreak,
    endBreak,

    // Departments
    departments,
    viewingDepartment,
    getDepartments,
    createDepartment,
    updateDepartment,
    deleteDepartment,

    // Shift Types
    shiftTypes,
    viewingShiftType,
    getShiftTypes,
    createShiftType,
    updateShiftType,
    deleteShiftType,

    // Break Types
    breakTypes,
    viewingBreakType,
    getBreakTypes,
    createBreakType,
    updateBreakType,
    deleteBreakType,

    // Export Timesheet
    exportTimesheet,
  };
});
